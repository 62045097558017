/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; }

.mat-form-field {
  width: 100%;
}

.clear {
  clear: both;
}

.form-control {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
}

.form-control:focus {
  box-shadow: none;
}

/**
 *  animations for carousel
 */

.animate-up {
  animation: fade-in-up 2s ease;
}

.animate-down {
  animation: fade-in-down 2s ease;
}

.animate-left {
  animation: fade-in-left 2s ease;
}

.animate-right {
  animation: fade-in-right 2s ease;
}

@keyframes fade-in-up {
  0% {
    opacity: 0%;
    transform: translateY(80px);
  }
  50% {
    opacity: 0%;
    transform: translateY(80px);
  }
  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0%;
    transform: translateY(-80px);
  }
  50% {
    opacity: 0%;
    transform: translateY(-80px);
  }
  100% {
    opacity: 100%;
    transform: translateY(0px);
  }
}

@keyframes fade-in-right {
  0% {
    opacity: 0%;
    transform: translateX(-80px);
  }
  50% {
    opacity: 0%;
    transform: translateX(-80px);
  }
  100% {
    opacity: 100%;
    transform: translateX(0px);
  }
}

@keyframes fade-in-left {
  0% {
    opacity: 0%;
    transform: translateX(80px);
  }
  50% {
    opacity: 0%;
    transform: translateX(80px);
  }
  100% {
    opacity: 100%;
    transform: translateX(0px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fade-out {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
